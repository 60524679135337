import React, {useEffect} from "react";
import "./styles/payment_pos.css";
import {Link, useNavigate, useParams} from "react-router-dom";
import {observer} from "mobx-react";
import {paymentPosStore} from "./PaymentPosStore";
import ModalNote from "./components/ModalNote";
import StorageService from "../../../common/service/StorageService";
import {handleFile} from "../../../common/helpers/UploadFileHelper";
import UploadFile from "../../../shared/components/form/UploadFile";
import {numberByDateNow, sliceText, utils} from "../../../common/utils/Utils";
import {productInPosCartStore} from "../productInPos/ProductInPosCartStore";
import {toastUtils} from "../../../common/utils/Toastutils";
import {productInPostStore} from "../productInPos/ProductInPostStore";
import ModalListCustomer from "../productInPos/components/modal/modalListCustomer/ModalListCustomer";
import ModalImageQr from "./components/ModalImageQr";
const  PaymentPosPage = () => {
    const navigate = useNavigate();
    const { postId } = useParams<{ postId: string }>();

    useEffect(() => {
        paymentPosStore.posid = postId
        const storedCart = StorageService.getArrayFromLS(`cartPos${postId}`);
        if (storedCart.length > 0) {
            productInPosCartStore.dataListProInCart = [...storedCart];
            if(productInPosCartStore.dataListProInCart.length > 0){
                if(paymentPosStore.listPayType.length > 0) {
                    var totalPay = 0
                    paymentPosStore.listPayType.map((item:any) => totalPay += item.price)
                    paymentPosStore.priceTotal = productInPosCartStore.CaculatorTotal(productInPosCartStore.dataListProInCart)
                    paymentPosStore.priceTotalAfterPayment = totalPay > productInPosCartStore.CaculatorTotal(productInPosCartStore.dataListProInCart) ? 0 : productInPosCartStore.CaculatorTotal(productInPosCartStore.dataListProInCart) - totalPay
                }else{
                    paymentPosStore.priceTotal = productInPosCartStore.CaculatorTotal(productInPosCartStore.dataListProInCart)
                    paymentPosStore.priceTotalAfterPayment = productInPosCartStore.CaculatorTotal(productInPosCartStore.dataListProInCart)
                }
            }
        }else{
            navigate(`/pos/${postId}/san-pham`)
        }
        if(StorageService.getLocalStore(`notePos${postId}`)){
            paymentPosStore.notePos = StorageService.getLocalStore(`notePos${postId}`)
        }
        if(StorageService.getObjectStore(`customerPos${postId}`)){
            productInPosCartStore.dataCustomer = StorageService.getObjectStore(`customerPos${postId}`)
        }

    }, [postId]);

    useEffect(() => {
        // Theo dõi nếu như xóa sẽ active luôn PTTT đầu tiên
        paymentPosStore.idPayTypeActive =  paymentPosStore.listPayType[0]?.id; 
    },[paymentPosStore.listPayType])

    return (
        <>
            <div className="payment">
                <div className="session1">
                    <h1>POS</h1>
                </div>
                <div className="session2">
                    <Link to={`/pos/${postId}/san-pham`}>
                    <span>
                        <img src="/assets/icon/prev.svg" alt="Quay lại"/>
                        Quay lại
                    </span>
                    </Link>
                    <p>Thanh toán</p>
                </div>
                <div className="session3">
                    <div className="leftSession3">
                        <div className="content">
                            <ul>
                                <li>
                                    <button onClick={() => {
                                        productInPostStore.isOpenModalListCustomer = true;
                                    }} className="btn btnModalCustomer btn-primary " type="button"
                                            data-toggle="modal" data-target="#exampleOpenCustomer">
                                       <span>
                                            <img src="/assets/icon/khachhang.svg" alt="khachhang"/>
                                            <p className="textName">{productInPosCartStore?.dataCustomer?.name ? sliceText(productInPosCartStore.dataCustomer.name,12) : 'Khách hàng'}</p>
                                       </span>
                                    </button>
                                </li>
                                <li>
                                    <button onClick={paymentPosStore.showModalNote}
                                            className="btn btnNotePay btn-primary " type="button"
                                            data-toggle="modal" data-target="#notePay">
                                        <span>
                                            <img src="/assets/icon/notes.svg" alt="Ghi chú đơn"/>
                                            Ghi chú đơn
                                        </span>
                                        {paymentPosStore.notePos ? (<p>{sliceText(paymentPosStore.notePos,30)}</p>) : (<></>)}
                                    </button>
                                </li>
                                <li>
                                    <p className="imageBank">
                                        <span>
                                            Ảnh chuyển khoản
                                        </span>
                                    </p>
                                    <UploadFile
                                        file={paymentPosStore.imageBank}
                                        onIconRemove={() => {
                                            paymentPosStore.handleRemoveImageBank();
                                            StorageService.removeLocalStore(`uploadedImageBank`);
                                        }}
                                        onFileChange={(e) => handleFile(e, paymentPosStore.handleSetFileImageBank)}
                                        onChooseImg={(url: string)=>{
                                            paymentPosStore.imageBank = url;
                                            localStorage.setItem("uploadedImageBank", url);
                                        }}
                                        code={numberByDateNow()}
                                        onResetState={() => {
                                            paymentPosStore.imageBank = "";
                                            StorageService.removeLocalStore(`uploadedImageBank`);
                                        }}
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="centerSession3">
                        <div className="contentFull">
                            <div className="content1">
                                {paymentPosStore.listPayType.length > 0 ? (
                                    <div className="nd2">
                                        <div className="leftNd">
                                            <p className="text1">Còn lại <span
                                                className="price_remaining">{utils.formatCurrency(paymentPosStore.priceTotalAfterPayment)}</span>
                                            </p>
                                            <p className="text2">Tổng tiền cần thanh toán <span
                                                className="total_mount">{utils.formatCurrency(paymentPosStore.priceTotal)}</span>
                                            </p>
                                        </div>
                                        <div className="rightNd">
                                            <p className="text3">Tiền thừa <span
                                                className="total_change">{paymentPosStore.pricePayment > paymentPosStore.priceTotal ? utils.formatCurrency(paymentPosStore.pricePayment - paymentPosStore.priceTotal) : utils.formatCurrency(0)}</span>
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="nd">
                                        <p className="priceTotal">
                                            {utils.formatCurrency(paymentPosStore.priceTotal)}
                                        </p>
                                        <p className="notePay">
                                            Vui lòng chọn một phương thức thanh toán
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="content2">
                                <div className="listNumber">
                                    <button className="numberPayId"
                                            data-number="1" onClick={() => {
                                        paymentPosStore.handleNumber(paymentPosStore.idPayTypeActive, 1)
                                    }}>
                                        1
                                    </button>
                                    <button onClick={() => {
                                        paymentPosStore.handleNumber(paymentPosStore.idPayTypeActive, 2)
                                    }} className="numberPayId"
                                            data-number="2">
                                        2
                                    </button>
                                    <button onClick={() => {
                                        paymentPosStore.handleNumber(paymentPosStore.idPayTypeActive, 3)
                                    }} className="numberPayId"
                                            data-number="3">
                                        3
                                    </button>

                                    <button onClick={() => {
                                        paymentPosStore.handleNumber(paymentPosStore.idPayTypeActive, 4)
                                    }} className="numberPayId"
                                            data-number="4">
                                        4
                                    </button>
                                    <button onClick={() => {
                                        paymentPosStore.handleNumber(paymentPosStore.idPayTypeActive, 5)
                                    }} className="numberPayId"
                                            data-number="5">
                                        5
                                    </button>
                                    <button onClick={() => {
                                        paymentPosStore.handleNumber(paymentPosStore.idPayTypeActive, 6)
                                    }} className="numberPayId"
                                            data-number="6">
                                        6
                                    </button>

                                    <button onClick={() => {
                                        paymentPosStore.handleNumber(paymentPosStore.idPayTypeActive, 7)
                                    }} className="numberPayId"
                                            data-number="7">
                                        7
                                    </button>
                                    <button onClick={() => {
                                        paymentPosStore.handleNumber(paymentPosStore.idPayTypeActive, 8)
                                    }} className="numberPayId"
                                            data-number="8">
                                        8
                                    </button>
                                    <button onClick={() => {
                                        paymentPosStore.handleNumber(paymentPosStore.idPayTypeActive, 9)
                                    }} className="numberPayId"
                                            data-number="9">
                                        9
                                    </button>
                                    <button onClick={() => {
                                        if (paymentPosStore.listPayType.length > 0) {
                                            var bank_amount = 0;
                                            paymentPosStore.listPayType.filter((item: any) => item.type === 2).map((item: any) => bank_amount += item.price)
                                            if (paymentPosStore.pricePayment < paymentPosStore.priceTotal) {
                                                if (productInPosCartStore.dataCustomer) {
                                                    if (bank_amount > 0) {
                                                        paymentPosStore.generateQr();
                                                    } else {
                                                        paymentPosStore.fetchSaveOrderPosEcommerce((route) => navigate(route));
                                                    }
                                                } else {
                                                    toastUtils.warning('Vui lòng chọn khách hàng')
                                                }
                                            } else {
                                                if (bank_amount > 0) {
                                                    paymentPosStore.generateQr();
                                                } else {
                                                    paymentPosStore.fetchSaveOrderPosEcommerce((route) => navigate(route));
                                                }
                                            }
                                        } else {
                                            toastUtils.warning('Vui lòng chọn phương thức thanh toán')
                                        }
                                    }} className="payClick">
                                        <span>Thanh toán</span>
                                    </button>
                                    <button onClick={() => {
                                        paymentPosStore.handleNumber(paymentPosStore.idPayTypeActive, 0)
                                    }} className="numberPayId"
                                            data-number="0">
                                        0
                                    </button>
                                    <button onClick={() => {
                                        paymentPosStore.handleRemove(paymentPosStore.idPayTypeActive)
                                    }} className="numberPayId">
                                        <img src="/assets/icon/delete.svg" alt="delete"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rightSession3">
                        <div className="content">
                            <ul>
                                <li>
                                    <p>Phương thức thanh toán</p>
                                </li>
                                <li>
                                    <button onClick={() => {
                                        paymentPosStore.handlePushPayType(1);
                                    }}>
                                    <span>
                                        <img src="/assets/icon/tienmat.svg" alt="Tiền mặt"/>
                                        Tiền mặt
                                    </span>
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => {
                                        paymentPosStore.handlePushPayType(2);
                                    }}>
                                    <span>
                                       <img src="/assets/icon/qr-scan.svg" alt="Tiền mặt"/>
                                        Chuyển khoản
                                    </span>
                                    </button>
                                </li>

                                <li>
                                    <p>Tóm tắt</p>
                                </li>
                            </ul>
                            <div id="contentPayType">
                                {paymentPosStore.listPayType.length > 0 && (
                                    <>
                                        {paymentPosStore.listPayType.map((item: any) => (
                                            <div
                                                key={item.id}
                                                className={item.id === paymentPosStore.idPayTypeActive ? 'itemActive item' : 'item'}
                                                onClick={() => {
                                                    paymentPosStore.idPayTypeActive = item.id
                                                }}>
                                                <p className="text">{item.type === 1 ? 'Tiền mặt' : 'Chuyển khoản'}</p>
                                                <p className="text-mobile">{item.type === 1 ? 'TM' : 'CK'}</p>
                                                <p className="price">{utils.formatCurrency(item.price ? item.price : 0)}</p>
                                                <button className="delete"
                                                        onClick={() => {
                                                            paymentPosStore.handleRemovePayType(item.id)
                                                        }}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24"
                                                         fill="none">
                                                        <path
                                                            d="M11.9996 0.998413C5.92461 0.998413 0.998047 5.92498 0.998047 12C0.998047 18.075 5.92461 23.0015 11.9996 23.0015C18.0746 23.0015 23.0012 18.075 23.0012 12C22.9918 5.92966 18.0699 1.00779 11.9996 0.998413ZM11.9996 21C7.03086 21 2.99961 16.9687 2.99961 12C2.99961 7.03123 7.03086 2.99998 11.9996 2.99998C16.9684 2.99998 20.9996 7.03123 20.9996 12C20.9949 16.9687 16.9684 20.9953 11.9996 21Z"
                                                            fill="#F97414"/>
                                                        <path
                                                            d="M21 12C20.9953 16.9688 16.9688 20.9953 12 21C7.03125 21 3 16.9688 3 12C3 7.03125 7.03125 3 12 3C16.9688 3 21 7.03125 21 12Z"
                                                            fill="#F97414"/>
                                                        <path
                                                            d="M17.5971 6.4029C17.0837 5.88958 16.2488 5.88958 15.7293 6.4029L12 10.1322L8.27069 6.39672C7.76355 5.87103 6.92863 5.85866 6.40294 6.36579C5.87725 6.87293 5.86488 7.70785 6.37202 8.23354C6.38439 8.24591 6.39057 8.25828 6.40294 8.26446L10.1323 12L6.40294 15.7355C5.87725 16.2426 5.86488 17.0775 6.37202 17.6032C6.87916 18.1289 7.71408 18.1413 8.23977 17.6341C8.25214 17.6218 8.2645 17.6156 8.27069 17.6032L12 13.8677L15.7293 17.6032C16.2364 18.1289 17.0714 18.1413 17.5971 17.6341C18.1227 17.127 18.1351 16.2921 17.628 15.7664C17.6156 15.754 17.6094 15.7416 17.5971 15.7355L13.8677 12L17.5971 8.26446C18.1104 7.75114 18.1104 6.91622 17.5971 6.4029Z"
                                                            fill="white"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        ))}
                                    </>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
                <ModalListCustomer/>
                <ModalNote/>
                <ModalImageQr/>
            </div>
        </>
    )
}
export default observer(PaymentPosPage);