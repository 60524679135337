import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {customerStore} from "../CustomerStore";
import SelectStatus from "../../../../shared/components/form/SelectStatus";
import useDebounce from "../../../../common/customHook/useDebounce";
import {useNavigate} from "react-router-dom";



const FilterCustomer = () => {
    const navigate = useNavigate();
    const debouncedSearchStaff = useDebounce(customerStore.searchTemp, 300);

    const handleSelectChange = (isSearchTriggered:boolean) => {
        customerStore.staticParamCustomer.phone = customerStore.searchTemp;
        updateUrlParams(isSearchTriggered);
    };
    const updateUrlParams = (isSearchTriggered: boolean) => {
        const searchParams = new URLSearchParams();
        if (debouncedSearchStaff) {
            searchParams.set("phone", debouncedSearchStaff);
        }
        customerStore.staticParamCustomer.page_number = 0;
        navigate({ search: searchParams.toString() }, { replace: true });
    };

    useEffect(() => {
        // roomStore.allowSearch: khi load lại trang không bay vào đây tránh mất search trên url
        if (customerStore.allowSearch && debouncedSearchStaff !== customerStore.staticParamCustomer.phone) {
            customerStore.staticParamCustomer.phone = debouncedSearchStaff;
            updateUrlParams(true);
        }
    }, [debouncedSearchStaff]);

    return (
        <div className="box-filter">
            <div className="search">
                <img src="/assets/icon/searchModel.svg" alt="searchModel"/>
                <input
                    type="text"
                    placeholder="Tìm kiếm theo số điện thoại ..."
                    value={customerStore.searchTemp}
                    onChange={(e: any) => {
                        customerStore.searchTemp = e.target.value
                        customerStore.allowSearch = true
                    }}
                />
            </div>
            {/*<SelectStatus*/}
            {/*    onChange={(e: any) => {*/}
            {/*        if (e === "1") {*/}
            {/*            customerStore.staticDataCustomer.status =*/}
            {/*                "active";*/}
            {/*        }*/}
            {/*        if (e === "0") {*/}
            {/*            customerStore.staticDataCustomer.status =*/}
            {/*                "deactive";*/}
            {/*        }*/}
            {/*        handleSelectChange(true);*/}
            {/*    }}*/}
            {/*    selected={() =>*/}
            {/*        customerStore.staticDataCustomer.status === "active"*/}
            {/*            ? "1"*/}
            {/*            : customerStore.staticDataCustomer.status === "deactive"*/}
            {/*                ? "0"*/}
            {/*                : "Trạng thái"*/}
            {/*    }*/}
            {/*    textOn="Hoạt động"*/}
            {/*    textOff="Ngừng hoạt động"*/}
            {/*/>*/}

            <div className="action--add" onClick={() => {
                customerStore.modalOpen = true;
                // customerStore.isCreating = true;
                // // customerStore.handleClearContent();
                // customerStore.modalName = "Thêm mới khách hàng";
                // productInPostStore.isOpenModalAddEditCustomer = true;
            }}>
        <span>
          <img src="/assets/icon/plus.svg" alt="add"/>
          Thêm mới
        </span>
            </div>
        </div>
    )
}

export default observer(FilterCustomer);