import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import './CloseMoneyControl.scss';
import {Button, Modal} from "antd";
import {salePointStore} from "../../../../SalePointStore";
import CurrencyType, {CurrencyTypeRef} from "../../../../components/CurrencyType";
import {CloseOutlined} from '@ant-design/icons';
import {useNavigate, useParams} from "react-router-dom";

const CloseMoneyControl: React.FC = () => {
    const {posId} = useParams<{ posId: string }>();
    const [cashOnOpen, setCashOnOpen] = useState("");
    const currencyTypeRef = useRef<CurrencyTypeRef>(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [transferAmount, setTransferAmount] = useState("");
    const [sessionNote, setSessionNote] = useState("");
    const navigate = useNavigate();


    const handleOpenCurrencyType = () => {
        salePointStore.showModalCurrency();
    };

    const formatMoneyDisplay = (amount: number): string => {
        const formattedAmount = amount.toLocaleString();
        if (formattedAmount.length > 12) {
            const firstPart = formattedAmount.slice(0, 5);
            const lastPart = formattedAmount.slice(-1);
            return `${firstPart}…${lastPart}`;
        }
        return formattedAmount;
    };

    const handleCurrencyConfirm = (total: number, details: string[]) => {
        const formattedTotal = String(total).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        setCashOnOpen(formattedTotal);

        const totalWithoutCommas = total.toString().replace(/,/g, '');

        const metaNoteStart = details.map((detail) => {
            const [quantity, name] = detail.split(" x ");
            return {
                name: `${name.trim()} `,
                quantity: quantity.trim(),
            };
        });

        const note = `Chi tiết tiền mặt:\n${details.join("\n")}`;
        setSessionNote(note);

        salePointStore.staticDataCloseMoney = {
            price_pos_cash: totalWithoutCommas,
            price_pos_bank: '',
            note_pos_close: [...metaNoteStart, ...details],
        };
    };

    const handleCashOnOpenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/,/g, '');
        if (/^\d*$/.test(value)) {
            const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            setCashOnOpen(formattedValue);
            setErrorMessage("");

            salePointStore.staticDataCloseMoney = {
                ...salePointStore.staticDataCloseMoney,
                price_pos_cash: value,
            };
        } else {
            setErrorMessage("Giá trị không hợp lệ! Vui lòng nhập số.");
        }
    };

    const handleTransferAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        salePointStore.isFirst = false // khi vừa mở popup lên chưa làm gì thì format kiểu "." ngược lại ","
        let value = e.target.value.replace(/,/g, '');
        if (/^\d*$/.test(value)) {  // Chỉ cho phép số và xóa ký tự không phải số
                const cleanedValue = value.replace(/^0+/, '') || "0";
                const formattedValue = cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                setTransferAmount(formattedValue);
                salePointStore.staticDataCloseMoney = {
                    ...salePointStore.staticDataCloseMoney,
                    price_pos_bank: cleanedValue,
                };
        }
    };

    const handleCloseTransferAmount = () => {
        setTransferAmount('');
        salePointStore.isFirst = false
        salePointStore.staticDataCloseMoney.price_pos_bank = '';
    };

    const handleSessionNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const note = e.target.value;
        setSessionNote(note);
        salePointStore.staticDataCloseMoney = {
            ...salePointStore.staticDataCloseMoney,
            note_pos_close: [note],
        };
    };


    //

    const handleCloseSession = async () => {

        if (!cashOnOpen.trim()) {
            setErrorMessage("Vui lòng nhập số tiền mặt!");
            return;
        }
        if (!sessionNote.trim()) {
            setErrorMessage("Vui lòng nhập ghi chú đóng phiên!");
            return;
        }

        salePointStore.staticDataCloseMoney.price_pos_bank = salePointStore.isFirst ? transferAmount.replace(/\./g, '') : transferAmount.replace(/,/g, '');

        await salePointStore.closeCash(navigate, ()=>{setTransferAmount('');});

        handleModalClose();
    };


    const calculateDifference = (cashOnOpen: string, cashAmount: number): string => {
        if (!cashOnOpen) {
            return `-${cashAmount.toLocaleString()} ₫`;
        }
        const cashOnOpenValue = parseInt(cashOnOpen.replace(/,/g, ''), 10);
        const difference = cashOnOpenValue - cashAmount;

        return formatMoneyDisplay(difference) + ' ₫';
    };

    const calculateDifferenceTitle = (cashOnOpen: string, cashAmount: number): string => {
        if (!cashOnOpen) {
            return `-${cashAmount.toLocaleString()} ₫`;
        }
        const cashOnOpenValue = parseInt(cashOnOpen.replace(/,/g, ''), 10);
        const difference = cashOnOpenValue - cashAmount;

        return difference.toLocaleString() + ' ₫';
    };

    const calculateDifferenceColor = (cashOnOpen: string, cashAmount: number): string => {
        if (!cashOnOpen) return 'red';

        const cashOnOpenValue = parseInt(cashOnOpen.replace(/,/g, ''), 10);
        const difference = cashOnOpenValue - cashAmount;

        return difference >= 0 ? 'green' : 'red';
    };


    const handleReset = () => {
        setCashOnOpen("");
        setTransferAmount("");
        setSessionNote("");
        setErrorMessage("");
        if (currencyTypeRef.current) {
            currencyTypeRef.current.resetCurrency();
        }
    };

    const handleModalClose = () => {
        handleReset();
        salePointStore.handleCashClose();
        salePointStore.isFirst = true
    };

    useEffect(() => {
        if (posId) {
            salePointStore.setPosId(posId);
        }

        if (salePointStore.listMoney) {
            setTransferAmount(formatMoneyDisplay(salePointStore.listMoney.bank_amount));
        }
    }, [posId, salePointStore.listMoney]);


    return (
        <Modal
            className="modal-form"
            footer={null}
            title="Đóng máy tính tiền"
            open={salePointStore.isModalCash}
            onCancel={handleModalClose}
            wrapClassName={`modalCloseMoney ${salePointStore.isLoadingBtn ? "disable" : ""}`}
        >
            <div className="CloseMoneyControl-container">
                <div className="line"></div>
                <table className="table table-borderless">
                    <thead className="header-table">
                    <tr>
                        <th>Phương thức thanh toán</th>
                        <th className="text-header-table">Dự kiến</th>
                        <th>Đã đếm</th>
                        <th className="text-header-table">Chênh lệch</th>
                    </tr>
                    </thead>
                    <tbody className="body-table">
                    <tr>
                        <td className="text-money">Tiền mặt</td>
                        <td className="count-money">
                            {salePointStore.listMoney ? formatMoneyDisplay(salePointStore.listMoney.cash_amount) + ' đ' : '0 đ'}
                        </td>
                        <td className="text-count-money">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Vui lòng nhập"
                                    value={cashOnOpen}
                                    onChange={handleCashOnOpenChange}
                                />
                                <span className="input-group-text"  style={{cursor: "pointer"}} onClick={handleOpenCurrencyType}>
                                    <img src="/assets/icon/money_dolar.svg" alt="Money Icon"/>
                                </span>
                            </div>
                            {errorMessage && !cashOnOpen && (
                                <span className="error-message" style={{color: "red"}}>{errorMessage}</span>
                            )}
                        </td>
                        <td className="count-money"
                            title= {salePointStore.listMoney ? calculateDifferenceTitle(cashOnOpen, salePointStore.listMoney.cash_amount) : '0 đ'}
                            style={{color: (salePointStore.listMoney && cashOnOpen ? calculateDifferenceColor(cashOnOpen, salePointStore.listMoney.cash_amount) : 'red')}}>
                            {salePointStore.listMoney ? calculateDifference(cashOnOpen, salePointStore.listMoney.cash_amount) : '0 đ'}
                        </td>

                    </tr>
                    <tr>
                        <td className="text-money">Chuyển khoản</td>
                        <td className="count-money">
                            {salePointStore.listMoney ? formatMoneyDisplay(salePointStore.listMoney.bank_amount) + ' đ' : '0 đ'}
                        </td>
                        <td className="input-group-money">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Vui lòng nhập"
                                    value={transferAmount}
                                    onChange={handleTransferAmountChange}
                                    disabled={salePointStore.isFirst}
                                />
                                <Button
                                    type="text"
                                    icon={<CloseOutlined/>}
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={handleCloseTransferAmount}
                                />
                            </div>
                            {/*{errorMessage && !transferAmount && (*/}
                            {/*    <span className="error-message" style={{color: "red"}}>Vui lòng nhập số tiền chuyển khoản!</span>*/}
                            {/*)}*/}
                        </td>
                        <td className="count-money"
                            style={{
                                color: salePointStore.listMoney && transferAmount
                                    ? parseInt(transferAmount.replace(/,/g, ''), 10) - salePointStore.listMoney.bank_amount >= 0 || parseInt(transferAmount.replace(/\./g, ''), 10) - salePointStore.listMoney.bank_amount >= 0
                                        ? 'green'
                                        : 'red'
                                    : 'black'
                            }}
                            title={salePointStore.listMoney && transferAmount && !salePointStore.isFirst
                                ?  (parseInt(transferAmount.replace(/,/g, ''), 10) - salePointStore.listMoney.bank_amount).toLocaleString() + ' ₫'
                                : salePointStore.listMoney && transferAmount && salePointStore.isFirst ? (parseInt(transferAmount.replace(/\./g, ''), 10) - salePointStore.listMoney.bank_amount).toLocaleString() + ' ₫' : "0đ"}
                            >
                            {salePointStore.listMoney && transferAmount && !salePointStore.isFirst
                                ?  formatMoneyDisplay(parseInt(transferAmount.replace(/,/g, ''), 10) - salePointStore.listMoney.bank_amount) + ' ₫'
                                : salePointStore.listMoney && transferAmount && salePointStore.isFirst ? (parseInt(transferAmount.replace(/\./g, ''), 10) - salePointStore.listMoney.bank_amount) + ' ₫' : "0đ"}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className="form-group">
                    <label>Ghi chú đóng phiên</label>
                    <textarea
                        className="form-control"
                        rows={3}
                        placeholder="Vui lòng nhập"
                        value={sessionNote}
                        onChange={handleSessionNoteChange}
                    />
                    {errorMessage && !sessionNote && (
                        <span className="error-message"
                              style={{color: "red", fontSize: "16px"}}>Vui lòng nhập ghi chú!</span>
                    )}
                </div>

                <div className="modal-footer">
                    {/*{errorMessage && (*/}
                    {/*    <span className="error-message" style={{color: "red", fontSize: "16px", marginBottom: "10px"}}>*/}
                    {/*            {errorMessage}*/}
                    {/*    </span>*/}
                    {/*)}*/}
                    <button type="button" disabled={salePointStore.isLoadingBtn} className="btn btn-warning" onClick={handleCloseSession}>
                        {salePointStore.isLoadingBtn
                            ? "Vui lòng đợi..."
                            : "Đóng máy tính tiền"}
                    </button>
                    <button type="button" className="btn btn-secondary" onClick={handleModalClose}>
                        Hủy bỏ
                    </button>
                </div>
            </div>
            <CurrencyType onConfirm={handleCurrencyConfirm} ref={currencyTypeRef}/>
        </Modal>
    );
};

export default observer(CloseMoneyControl);
