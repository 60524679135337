import {getRequest} from "../../../common/helpers/RequestHelper";


class PermissionSiteService {
    apiGet = "api/v1/tenant/get_permission_site";
    async fetchPermissionSite(data:any): Promise<any> {
        return getRequest(`${this.apiGet}`, data);
    }
}

export const permissionSiteService = new PermissionSiteService();