import React, { useEffect, useState } from "react";
import "./HeaderProductInPos.scss";
import { Popover } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { productInPostStore } from "../../ProductInPostStore";
import CloseMoneyControl from "../modal/closeMoneyControl/CloseMoneyControl";
import { salePointStore } from "../../../SalePointStore";
import { handleKeyPressSpecialCharacters } from "../../../../../common/utils/Utils";

const HeaderProductInPos = ({search = true}: {search ?: boolean}) => {
    const [isCloseMoneyControlVisible, setIsCloseMoneyControlVisible] = useState(false);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { postId } = useParams<{ postId: string }>();

    const handleClosePopover = () => {
        setPopoverVisible(false); // Đóng Popover
    };


    const updateUrlParams = () => {
        const searchParams = new URLSearchParams();
        if (productInPostStore.dataFilterProduct.search) {
            searchParams.set("search", productInPostStore.dataFilterProduct.search);
        }
        navigate({ search: searchParams.toString() }, { replace: true });
        productInPostStore.getListProduct();
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        productInPostStore.dataFilterProduct.search = searchParams.get("search") || "";
    }, [location.search]);

    useEffect(() => {
        if (postId) {
            salePointStore.setPosId(postId);
        }
    }, [postId]);

    const handleResetFilter = () => {
        productInPostStore.dataFilterProduct.search = "";
        productInPostStore.dataProduct = [];
        navigate({ search: "" }, { replace: true });
    };

    const content = (
        <div className="header-boxPopoverProductInPos">
            <p>
                <Link to={`/pos/chi-tiet-don-hang/${postId}`} style={{ textDecoration: "none", color: "#061020" }}>
                    Đơn hàng
                </Link>
            </p>

            <p>
                <Link to={`/pos/${postId}/cong-no`} style={{ textDecoration: "none", color: "#061020" }}>
                    Công nợ
                </Link>
            </p>
            <p
                onClick={() => {
                    setIsCloseMoneyControlVisible(true);
                    salePointStore.showModalCash();
                    salePointStore.getTotalMoneyPos();
                    salePointStore.isFirst = true
                    handleClosePopover();
                }}
                style={{ cursor: "pointer", color: "#061020" }}
            >
                Đóng máy tính tiền
            </p>
        </div>
    );

    return (
        <div className="HeaderProductInPos">
            <div className="header-container">
                <div className="header-boxLeft">
                    <h1>POS</h1>
                    {search &&
                        <form
                            className="filter-search filter-search-hidden"
                            onSubmit={(e) => {
                                e.preventDefault();
                                updateUrlParams();
                            }}
                        >
                            <input
                                type="text"
                                name="keyword"
                                value={productInPostStore.dataFilterProduct.search}
                                onKeyPress={handleKeyPressSpecialCharacters}
                                id="keyword"
                                required
                                onChange={(e: any) => {
                                    productInPostStore.dataFilterProduct.search = e.currentTarget.value;
                                }}
                                placeholder="Tìm kiếm sản phẩm"
                            />
                            <button type="submit">
                                <img src="/assets/icon/search.svg" alt="search" />
                            </button>
                        </form> 
                    }
                    {productInPostStore.dataFilterProduct.search && search && (
                        <div className="header-boxLeft__reset" onClick={handleResetFilter}>
                            Đặt Lại
                        </div>
                    )}
                </div>
                <div className="header-boxRight">
                    <Popover
                        placement="bottomRight"
                        title={""}
                        arrow={false}
                        content={content}
                        trigger="click"
                        open={popoverVisible}
                        onOpenChange={(visible) => setPopoverVisible(visible)}
                    >
                        <button className="header-bars">
                            <i className="fas fa-bars"></i>
                        </button>
                    </Popover>
                </div>
            </div>
            {isCloseMoneyControlVisible && <CloseMoneyControl/>}
        </div>
    );
};

export default observer(HeaderProductInPos);
