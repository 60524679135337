import {HttpStatusCode} from "axios";
import {makeAutoObservable, toJS} from "mobx";
import {permissionSiteService} from "./PermissionSiteService";
import StorageService from "../../../common/service/StorageService";

class PermissionSiteStore {
    isLoadingBtn: boolean = false;
    InfoPermissionSite: any = {};
    isOpenModal: boolean = false;
    isProcessing: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    handlePermissionCheck() {
        if (this.InfoPermissionSite.permission_site == 3) {
            const domainName = StorageService.getLocalStore("domainName") || "";
            console.log(domainName);
            window.open(domainName, "_blank");
        } else {
            this.isOpenModal = true;
        }
    }

    async fetchPermissionSite() {
        if (this.isProcessing === false) {
            this.isProcessing = true
            const domain_name = StorageService.getLocalStore(`idDomain`);
            const params = {
                domain_name: domain_name
            }
            const result = await permissionSiteService.fetchPermissionSite(params);
            if (result.status === HttpStatusCode.Ok) {
                this.isProcessing = false;
                this.InfoPermissionSite = result.body;
            }
        }
    }
}

export const permissionSiteStore = new PermissionSiteStore();

