import {deleteRequest, getRequest, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import { TFormCustomer } from "./CustomerStore";


class CustomerService{
    apiGet = "api/v1/customer"
    apiGetPos = "api/v1/location/list"

    public fetchCustomerGettingAll(ParamCustomer: any): Promise<any>{
        const queryParams = [
            ParamCustomer.location_id ? `location_id=${ParamCustomer.location_id}` : "",
            ParamCustomer.phone ? `phone=${ParamCustomer.phone}` : "",
            ParamCustomer.page_number ? `page_number=${ParamCustomer.page_number}` : "",
            ParamCustomer.per_page ? `per_page=${ParamCustomer.per_page}` : "",
        ]
            .filter(Boolean)
            .join("&");
        return getRequest(`${this.apiGet}?${queryParams}`);
    }
    public fetchCustomerAdding(customerData:any): Promise<any>{
        return postRequest(`${this.apiGet}`, customerData);
    }
    public fetchCustomerDeleting(customerID:any): Promise<any>{
        return deleteRequest(`${this.apiGet}/${customerID}`);
    }
    public fetchCustomerEditing(idCustomer: string, data: TFormCustomer): Promise<any>{
        return putRequest(`${this.apiGet}/${idCustomer}`,data);
    }

    public fetchLocationGettingAll(): Promise<any>{
        return getRequest(`${this.apiGetPos}`);
    }




    public fetchProvices(): Promise<any> {
        return getRequest(`api/v1/provinces`);
    }
    public fetchDistricts(provinceId: string): Promise<any> {
        return getRequest(`api/v1/districts?province_id=${provinceId}`);
    }
    public fetchWards(districtId: string): Promise<any> {
        return getRequest(`api/v1/wards?district_id=${districtId}`);
    }

}

export const customerService = new CustomerService();