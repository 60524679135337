import React from "react";
import {observer} from "mobx-react";
import {productStore} from "../../ProductStore";
import {Button, Checkbox, Form, Input, InputNumber, Space} from "antd";
import SelectStatusNumber from "../../../../../shared/components/form/SelectStatusNumber";
import {handleFileVariant} from "../../../../../common/helpers/UploadFileHelper";
import UploadFile from "../../../../../shared/components/form/UploadFile";
import {showDelete} from "../../../../../shared/components/showDelete";
import {categoryProductStore} from "../../../categoryProduct/CategoryProductStore";
import {handleKeyPress, numberByDateNow} from "../../../../../common/utils/Utils";
interface DetailVariantProps{
    index : number;
}
const DetailVariant: React.FC<DetailVariantProps> = ({ index })=>  {
    const handleQtyChange = (indexStore:any,index:number, delta:any) => {
        const item = productStore.dataProductListVariant[index].quantity[indexStore];
        const newQty = Math.max(0, (item.qty || 0) + delta);
        item.qty = newQty;
    };
    const handleInputChange = (indexStore:any,index:number, value:any) => {
        const newQty = Math.max(0, value);
        productStore.dataProductListVariant[index].quantity[indexStore].qty = newQty;
    };
    return (
        <>
            <div className="form-create-product ">
                <UploadFile
                        file={productStore.dataProductListVariant[index].thumbnail}
                        onIconRemove={() => productStore.dataProductListVariant[index].thumbnail = ''}
                        onChooseImg={(url: string)=>{
                            productStore.dataProductListVariant[index].thumbnail = url
                        }}
                        // onResetState={()=>{
                        //     productStore.dataProductListVariant[index].thumbnail = ""
                        // }}
                        code={numberByDateNow()}
                    />
                <div className="grid-2 gap-20">
                    <div className="item-form">
                        <Form.Item
                            key={`price${index}`}
                            name={`price${index}`}
                            label="Giá bán"
                            rules={[{required: true, message: "Không được để trống!"}]}
                        >
                            <InputNumber
                                onKeyPress={handleKeyPress}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                parser={(value) => value ? parseInt(value.replace(/\$\s?|(,*)/g, ""), 10) : 0}
                                value={productStore.dataProductListVariant[index].price} // Controlled input
                                onChange={(value) => {
                                    productStore.dataProductListVariant[index].price = value ? value.toString() : "0"; // Update MobX state
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className="item-form">
                        <Form.Item
                            key={`sku${index}`}
                            label="SKU"
                            name={`sku${index}`}
                            rules={[{required: true, message: "Không được để trống!"},{min: 4, message: "Sku phải từ 4 ký tự trở lên"}]}
                        >
                            <Input
                                value={productStore.dataProductListVariant[index].sku}
                                onChange={(event) => {
                                    productStore.dataProductListVariant[index].sku = event.target.value;
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className="item-form">
                        <Form.Item
                            key={`price_compare${index}`}
                            name={`price_compare${index}`}
                            label="Giá gốc"
                            rules={[{required: true, message: "Không được để trống!"},
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const priceOrigin = getFieldValue(`price${index}`);
                                        if (value >= 0 && priceOrigin && priceOrigin > value) {
                                            return Promise.reject(new Error('Giá gốc không được nhỏ hơn giá bán!'));
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <InputNumber
                                onKeyPress={handleKeyPress}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                parser={(value) => value?.replace(/\$\s?|(,*)/g, "") as unknown as number}
                                defaultValue={productStore.dataProductListVariant[index].price_compare}

                                onChange={(value) => {
                                    productStore.dataProductListVariant[index].price_compare = `${value}`;
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className="item-form">
                        <Form.Item
                            key={`unit${index}`}
                            name={`unit${index}`}
                            label="Đơn vị tính"
                            rules={[{required: true, message: "Không được để trống!"}]}
                        >
                            <Input
                                defaultValue={productStore.dataProductListVariant[index].unit}
                                onChange={(event) => {
                                    productStore.dataProductListVariant[index].unit = event.target.value;
                                }}/>
                        </Form.Item>
                    </div>
                    <div className="item-form">
                        <Form.Item
                            key={`weight${index}`}
                            name={`weight${index}`}
                            label="Cân nặng (gram)"
                            rules={[{required: true, message: "Không được để trống!"}]}
                        >
                            <Input
                                onKeyPress={handleKeyPress}
                                type="text"
                                defaultValue={productStore.dataProductListVariant[index].weight}
                                onChange={(event) => {
                                    productStore.dataProductListVariant[index].weight = event.target.value;
                                }}/>
                        </Form.Item>
                    </div>
                    <div className="item-form">
                        <Form.Item
                            key={`height${index}`}
                            name={`height${index}`}
                            label="Chiều cao (cm)"
                            rules={[{required: true, message: "Không được để trống!"}]}
                        >
                            <Input
                                onKeyPress={handleKeyPress}
                                type="text"
                                defaultValue={productStore.dataProductListVariant[index].height}
                                onChange={(event) => {
                                    productStore.dataProductListVariant[index].height = event.target.value;
                                }}/>
                        </Form.Item>
                    </div>
                    <div className="item-form">
                        <Form.Item
                            key={`length${index}`}
                            name={`length${index}`}
                            label="Chiều dài (cm)"
                            rules={[{required: true, message: "Không được để trống!"}]}
                        >
                            <Input
                                onKeyPress={handleKeyPress}
                                type="text"
                                defaultValue={productStore.dataProductListVariant[index].length}
                                onChange={(event) => {
                                    productStore.dataProductListVariant[index].length = event.target.value;
                                }}/>
                        </Form.Item>
                    </div>
                    <div className="item-form">
                        <Form.Item
                            key={`width${index}`}
                            name={`width${index}`}
                            label="Chiều rộng (cm)"
                            rules={[{required: true, message: "Không được để trống!"}]}
                        >
                            <Input
                                onKeyPress={handleKeyPress}
                                type="text"
                                defaultValue={productStore.dataProductListVariant[index].width}
                                onChange={(event) => {
                                    productStore.dataProductListVariant[index].width = event.target.value;
                                }}/>
                        </Form.Item>
                    </div>
                </div>
                <div className="item-form">
                    <Form.Item key={`status${index}`} label="Tình trạng"
                               rules={[{required: true, message: 'Không được để trống!'}]}>
                        <SelectStatusNumber
                            selected={() => productStore.dataProductListVariant[index].status}
                            onChange={(value: any) => productStore.dataProductListVariant[index].status = value}
                            textOn={`Còn hàng`} textOff={`Hết hàng`}/>
                    </Form.Item>
                </div>
                <div className="item-form">
                    <Form.Item key={`allow_inventory${index}`} label="Loại tồn kho"
                               rules={[{required: true, message: 'Không được để trống!'}]}>
                        <SelectStatusNumber
                            selected={() => productStore.dataProductListVariant[index].allow_inventory}
                            onChange={(value: any) => productStore.dataProductListVariant[index].allow_inventory = value}
                            textOn={`Cho phép quản lý tồn kho`} textOff={`Không cho phép quản lý tồn kho`}/>
                    </Form.Item>
                </div>
                {productStore.dataProductListVariant[index].allow_inventory === 1 && (
                    <>
                        <div className="item-form-inventory">
                            {productStore.dataProductListVariant[index].quantity.map((itemStore: any, indexStore: number) => (
                                <div className="item-store" key={indexStore}>
                                    <p>{itemStore.name}</p>
                                    <div className="qty">
                                        <div className="minus">
                                            <button
                                                type="button"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    handleQtyChange(indexStore, index, -1);
                                                }}
                                            >
                                                -
                                            </button>
                                        </div>
                                        <Input
                                            type="number"
                                            placeholder="0"
                                            value={productStore.dataProductListVariant[index].quantity[indexStore].qty || 0}
                                            onChange={(e) => handleInputChange(indexStore, index, parseInt(e.target.value, 10))}
                                            min="0"
                                            max="99999"
                                            maxLength={16}
                                        />
                                        <div className="plus">
                                            <button
                                                type="button"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    handleQtyChange(indexStore, index, 1);
                                                }}
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
                {productStore.dataProduct.product_type === 1 && (
                    <div className="removeVariant">
                        <a href="javascript:void(0)" onClick={() => {
                            showDelete.showDeleteConfirm(
                                "Xác nhận xoá",
                                "Bạn chắc chắn muốn xoá? (Hành động này không thể hoàn tác)",
                                () =>
                                    productStore.fetchDeleteVariant(productStore.dataProductListVariant[index].id)
                            );

                        }}>
                            Xóa
                        </a>
                    </div>
                )}

            </div>
        </>
    )
}
export default observer(DetailVariant);