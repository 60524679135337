import { Modal, Radio, Input, Form } from "antd";
import { observer } from "mobx-react";
import UploadFile from "../../../../shared/components/form/UploadFile";
import { groupProductStore } from "../GroupProductStore";
import { handleFile } from "../../../../common/helpers/UploadFileHelper";
import * as yup from "yup";
import OptionalOption from "./form-components/OptionalOption";
import ConditionalOption from "./form-components/ConditionalOption";
import { useEffect } from "react";
import { toastUtils } from "../../../../common/utils/Toastutils";
import { numberByDateNow } from "../../../../common/utils/Utils";

const GroupProductModal = () => {
    const [form] = Form.useForm();
    const { TextArea } = Input;
    type FieldType = {
        group_name?: string;
        group_description?: string;
        seo_title?: string;
        seo_description?: string;
    };
    useEffect(() => {
        form.setFieldsValue({
            group_name: groupProductStore.staticDataGroupProduct.name,
            group_description:
                groupProductStore.staticDataGroupProduct.description,
            seo_title: groupProductStore.staticDataGroupProduct.seo_title,
            seo_description:
                groupProductStore.staticDataGroupProduct.seo_description,
        });
    }, [
        groupProductStore.staticDataGroupProduct.name,
        groupProductStore.staticDataGroupProduct.description,
        groupProductStore.staticDataGroupProduct.seo_description,
        groupProductStore.staticDataGroupProduct.seo_title,
    ]);

    useEffect(()=>{
        groupProductStore.getDataListProductAll();
    },[])

    return (
        <Modal
            className="modal-form modal-form--group-product"
            afterClose={() => {
                groupProductStore.getDataListProductAll();
                groupProductStore.handleResetModal();
            }}
            title={groupProductStore.modalName}
            footer={null}
            open={groupProductStore.openModal}
            onCancel={() => {
                groupProductStore.openModal = groupProductStore.isLoadingBtn;
            }}
        >
            <Form
                form={form}
                id="group-product-modal"
                onFinish={(values) => {
                    console.log("check values", values);
                    groupProductStore.staticDataGroupProduct.name =
                        values.group_name;
                    groupProductStore.staticDataGroupProduct.description =
                        values.group_description;
                    groupProductStore.staticDataGroupProduct.seo_description =
                        values.seo_description;
                    groupProductStore.staticDataGroupProduct.seo_title =
                        values.seo_title;
                    groupProductStore.isCreating === true
                        ? groupProductStore.addGroupProduct()
                        : groupProductStore.modifyGroupProduct();
                }}
                initialValues={{
                    group_name: groupProductStore.staticDataGroupProduct.name,
                    group_description:
                        groupProductStore.staticDataGroupProduct.description,
                    seo_title:
                        groupProductStore.staticDataGroupProduct.seo_title,
                    seo_description:
                        groupProductStore.staticDataGroupProduct
                            .seo_description,
                }}
            >
                <Form.Item<FieldType>
                    className="item-form"
                    label="Tên nhóm sản phẩm"
                    name="group_name"
                    rules={[
                        {
                            required: true,
                            message: "Tên nhóm sản phẩm không được để trống",
                        },
                        {
                            max: 150,
                            message:
                                "Tên nhóm sản phẩm không được vượt quá 150 ký tự",
                        },
                    ]}
                >
                    <Input
                        maxLength={200}
                        placeholder="Vui lòng nhập"
                        onChange={(e: any) => {
                            if (e.target.value.length > 149) {
                                toastUtils.warning(
                                    "Tên thuộc tính quá dài, tối đa 150 ký tự",
                                    ""
                                );
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item<FieldType>
                    className="item-form"
                    label="Mô tả nhóm sản phẩm"
                    name="group_description"
                    rules={[
                        {
                            required: true,
                            message: "Mô tả nhóm sản phẩm không được để trống",
                        },
                    ]}
                >
                    <Input placeholder="Vui lòng nhập" />
                </Form.Item>
                <UploadFile
                    text={`Hình ảnh`}
                    description={`Hình ảnh Nhóm sản phẩm`}
                    file={
                        groupProductStore.staticDataGroupProduct.thumbnail ===
                        ""
                            ? ""
                            : groupProductStore.staticDataGroupProduct
                                  .thumbnail == null
                            ? ""
                            : groupProductStore.staticDataGroupProduct.thumbnail
                    }
                    onIconRemove={() => {
                        groupProductStore.handleCloseIcon();
                    }}
                    onFileChange={(e) =>
                        handleFile(e, groupProductStore.changeFile)
                    }
                    onChooseImg={(url: string)=>{
                        groupProductStore.staticDataGroupProduct.thumbnail = url
                    }}
                    code={numberByDateNow()}
                />
                <div className="item-form item-form--custom">
                    <label>Các điều kiện</label>
                    <div className="item-form__guide">
                        Các sản phẩm sẽ được tự động đưa vào danh mục này dựa
                        vào các điều kiện bên dưới.
                    </div>
                    <Radio.Group
                        className="radio-group"
                        onChange={(e) => {
                            groupProductStore.staticDataGroupProduct.type =
                                e.target.value;
                            // groupProductStore.checkIfRequireSeo();
                        }}
                        value={groupProductStore.staticDataGroupProduct.type}
                    >
                        <Radio
                            className="radio-group-item"
                            value={0}
                            defaultChecked
                        >
                            Tự chọn sản phẩm
                        </Radio>
                        <Radio className="radio-group-item" value={1}>
                            Sản phẩm tự động cập nhật dựa trên điều kiện
                        </Radio>
                    </Radio.Group>
                </div>
                {groupProductStore.staticDataGroupProduct.type === 0 ? (
                    <>
                        <OptionalOption />
                    </>
                ) : (
                    // DIVIDER///////////////////////////////////////////////////////////////
                    <>
                        <ConditionalOption />
                    </>
                )}
                <Form.Item<FieldType>
                    dependencies={[() => groupProductStore.isRequireSeo]} // Lắng nghe thay đổi
                    className="item-form"
                    label="Tiêu đề SEO"
                    name="seo_title"
                    // rules={[
                    //     {
                    //         required: groupProductStore.isRequireSeo,
                    //         message: "Tiêu đề SEO không được để trống",
                    //     },
                    // ]}
                >
                    <Input placeholder="Vui lòng nhập" />
                </Form.Item>
                <Form.Item<FieldType>
                    dependencies={[() => groupProductStore.isRequireSeo]} // Lắng nghe thay đổi
                    className="item-form"
                    label="Mô tả SEO"
                    name="seo_description"
                    // rules={[
                    //     {
                    //         required: groupProductStore.isRequireSeo,
                    //         message: "Mô tả SEO không được để trống",
                    //     },
                    // ]}
                >
                    <TextArea placeholder="Vui lòng nhập" />
                </Form.Item>
                <div className="btnCreateModal">
                    <button
                        className="cancelModal"
                        type="button"
                        onClick={() => {
                            groupProductStore.openModal = false;
                        }}
                    >
                        Đóng
                    </button>
                    <button
                        className="submitForm"
                        type="submit"
                        form="group-product-modal"
                        disabled={groupProductStore.isLoadingBtn}
                    >
                        {groupProductStore.isLoadingBtn
                            ? "Vui lòng chờ..."
                            : "Lưu"}
                    </button>
                </div>
            </Form>
        </Modal>
    );
};

export default observer(GroupProductModal);
