import React, { useEffect } from "react";
import { observer } from "mobx-react";
import "./styles/header.css";
import Navbar from "./Navbar/Navbar";
import {
    createSearchParams,
    Link,
    useLocation,
    useNavigate,
} from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Dropdown, Popover, Space } from "antd";
import { logoutStore } from "../../../pages/user/logout/LogoutStore";
import { headerStore } from "./HeaderStore";
import { ThreeDot } from "react-loading-indicators";
import { infoStore } from "../../../pages/user/info/InfoStore";
import StorageService from "../../../common/service/StorageService";
import { dashboardStore } from "../../../pages/dashboard/DashboardStore";
import Notifications from "./notification/Notifications";
import { notificationStore } from "./notification/NotificationStore";

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const items: MenuProps["items"] = [
        {
            label: (
                <Link
                    className="dropdown-header-menu"
                    to="/thong-tin-tai-khoan"
                >
                    Thông tin người dùng
                </Link>
            ),
            key: "0",
        },
        {
            label: (
                <a
                    className="dropdown-header-menu"
                    href="javascript:void(0)"
                    onClick={(event) => logoutStore.logout()}
                >
                    Đăng xuất
                </a>
            ),
            key: "1",
        },
    ];

    useEffect(() => {
        headerStore.fetchDomain().then();
        infoStore.fetchGetInfoUser();
        // const storedAvatar = localStorage.getItem("avatarUser");
        // if (storedAvatar) {
        //     infoStore.isAvatar = storedAvatar;
        // } else if (infoStore.dataUser.avatar) {
        //     // Nếu chưa có avatar trong localStorage, lấy từ infoStore và lưu vào localStorage
        //     infoStore.isAvatar = infoStore.dataUser.avatar;
        //     localStorage.setItem("avatarUser", infoStore.dataUser.avatar);
        // }
    }, []);

    useEffect(() => {
        notificationStore.getNotice();
    }, [location.pathname]);

    return (
        <>
            <header>
                <div className="headerTop">
                    <div className="container">
                        <div className="headerContent">
                            <div className="headerLogo">
                                <a href="/">
                                    <img
                                        style={{ width: "100%", height: "60%" }}
                                        src="/assets/images/logo-xweb.svg"
                                        alt="Logo"
                                    />
                                </a>
                            </div>
                            <div className="headerRight">
                                <div className="headerRightContent">
                                    <div className="headerPlan">
                                        <i
                                            className="btn btn-secondary"
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            title="Gói website hiện tại: Basic 1 tháng (1-31/07/2024)"
                                        >
                                            Gói POS còn lại{" "}
                                            {headerStore.isLoading === true ? (
                                                <ThreeDot
                                                    variant="bob"
                                                    color="#6D727C"
                                                    size="small"
                                                    textColor=""
                                                />
                                            ) : (
                                                headerStore.timePackageWebsite
                                            )}{" "}
                                            ngày
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="12"
                                                height="12"
                                                viewBox="0 0 12 12"
                                                fill="none"
                                            >
                                                <path
                                                    d="M6.74978 3.25063C6.74978 3.44954 6.67077 3.64031 6.53011 3.78096C6.38946 3.92161 6.1987 4.00063 5.99978 4.00063C5.80087 4.00063 5.61011 3.92161 5.46945 3.78096C5.3288 3.64031 5.24978 3.44954 5.24978 3.25063C5.24978 3.05172 5.3288 2.86095 5.46945 2.7203C5.61011 2.57965 5.80087 2.50063 5.99978 2.50063C6.1987 2.50063 6.38946 2.57965 6.53011 2.7203C6.67077 2.86095 6.74978 3.05172 6.74978 3.25063V3.25063ZM11.9998 9.50063V6.17063C12.0184 4.62936 11.4533 3.13808 10.4179 1.99622C9.38255 0.854356 7.95353 0.14645 6.41778 0.0146317C5.56061 -0.0453395 4.70055 0.0795015 3.8958 0.380708C3.09105 0.681915 2.36039 1.15246 1.75324 1.76051C1.14609 2.36855 0.676633 3.09991 0.37662 3.90511C0.0766065 4.7103 -0.0469593 5.57055 0.0142825 6.42763C0.235283 9.60463 3.04078 12.0006 6.54128 12.0006H9.49978C10.1626 11.9998 10.798 11.7362 11.2667 11.2675C11.7353 10.7989 11.999 10.1634 11.9998 9.50063V9.50063ZM6.34978 1.01263C7.63301 1.12615 8.82574 1.72109 9.68833 2.67791C10.5509 3.63473 11.0194 4.88254 10.9998 6.17063V9.50063C10.9998 9.89846 10.8417 10.28 10.5604 10.5613C10.2791 10.8426 9.89761 11.0006 9.49978 11.0006H6.54128C3.52428 11.0006 1.19978 9.05063 1.01228 6.35863C0.963059 5.67292 1.05577 4.98443 1.28462 4.33617C1.51348 3.6879 1.87356 3.0938 2.34237 2.59097C2.81118 2.08814 3.37865 1.68739 4.00932 1.41376C4.63999 1.14012 5.32031 0.999486 6.00778 1.00063C6.12128 1.00063 6.23578 1.00513 6.34978 1.01263ZM6.99978 9.00063V6.00063C6.99978 5.73542 6.89443 5.48106 6.70689 5.29353C6.51935 5.10599 6.265 5.00063 5.99978 5.00063H5.49978C5.36717 5.00063 5.24 5.05331 5.14623 5.14708C5.05246 5.24085 4.99978 5.36802 4.99978 5.50063C4.99978 5.63324 5.05246 5.76042 5.14623 5.85419C5.24 5.94795 5.36717 6.00063 5.49978 6.00063H5.99978V9.00063C5.99978 9.13324 6.05246 9.26042 6.14623 9.35419C6.24 9.44795 6.36718 9.50063 6.49978 9.50063C6.63239 9.50063 6.75957 9.44795 6.85334 9.35419C6.9471 9.26042 6.99978 9.13324 6.99978 9.00063Z"
                                                    fill="#061020"
                                                />
                                            </svg>
                                        </i>
                                    </div>
                                    <div className="headerNoti">
                                        <Popover
                                            placement="bottomRight"
                                            title={""}
                                            arrow={false}
                                            content={<Notifications />}
                                            trigger="click"
                                            overlayClassName="popover-NotiHeader"
                                            open={
                                                notificationStore.isOpenPopoverNotice
                                            }
                                            onOpenChange={(visible) => {
                                                notificationStore.isOpenPopoverNotice =
                                                    visible;
                                            }}
                                        >
                                            <div
                                                onClick={async () => {
                                                    notificationStore.isOpenPopoverNotice =
                                                        notificationStore.isOpenPopoverNotice
                                                            ? true
                                                            : false;
                                                    notificationStore.listNotice =
                                                        [];
                                                    notificationStore.params.page = 1;
                                                    notificationStore.isOpenPopoverNotice &&
                                                        (await notificationStore.getNotice());
                                                }}
                                            >
                                                {notificationStore.countNotice
                                                    .unRead === 0 ? (
                                                    <img
                                                        src="/assets/icon/icon_No-notice.svg"
                                                        alt="noti"
                                                    />
                                                ) : (
                                                    <img
                                                        src="/assets/icon/icon_noti.svg"
                                                        alt="noti"
                                                    />
                                                )}
                                            </div>
                                        </Popover>
                                    </div>
                                    <div className="headerSetting">
                                        <Link
                                            to={`/cai-dat/quan-tri-vien`}
                                            title="Cài đặt"
                                        >
                                            <img
                                                src="/assets/icon/icon_setting.svg"
                                                alt="setting"
                                            />
                                        </Link>
                                    </div>
                                    <div
                                        className="headerUser"
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Dropdown
                                            menu={{ items }}
                                            trigger={["click"]}
                                        >
                                            <a
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <Space>
                                                    <span>
                                                        {localStorage.getItem(
                                                            "avatarUSer"
                                                        ) === null ||
                                                        localStorage.getItem(
                                                            "avatarUSer"
                                                        ) === "null" ? (
                                                            <img
                                                                className="icon_header_user"
                                                                src="/assets/icon/icon_user.svg"
                                                                alt="user"
                                                            />
                                                        ) : (
                                                            <img
                                                                className="icon_header_user"
                                                                src={`${localStorage.getItem(
                                                                    "avatarUSer"
                                                                )}`}
                                                                alt="user"
                                                            />
                                                        )}
                                                        {localStorage.getItem(
                                                            "nameDomain"
                                                        )}
                                                        <img
                                                            className="icon_header_bottom"
                                                            src="/assets/icon/icon_header_bottom.svg"
                                                            alt="header bottom"
                                                        />
                                                    </span>
                                                </Space>
                                            </a>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="navbar navbar-main">
                    <div className="container">
                        <Navbar />
                        <ul className="navbarSell">
                            <li>
                                <Link to="/danh-sach-pos" title="Bán hàng">
                                    <img
                                        src="/assets/icon/icon_sell.svg"
                                        alt="Bán hàng"
                                    />
                                    <span>Bán hàng</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        </>
    );
};
export default observer(Header);
