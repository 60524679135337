import React, { useEffect, useState } from "react";
import './styles/salePoint.scss';
import { observer } from "mobx-react";
import { Popover } from "antd";
import SelectCustom from "../../shared/components/form/SelectCustom";
import { salePointStore } from "./SalePointStore";
import MoneyControl from "./components/MoneyControl";
import Loading from "../../shared/components/loading/Loading";
import { Link } from "react-router-dom";
import NoPos from "../../shared/components/NoPos";

const SalePointPage = () => {
    const formatCurrency = (amount: number): string => {
        return amount.toLocaleString("vi-VN", {
            style: "currency",
            currency: "VND",
        });
    };

    const [showDropdown, setShowDropdown] = useState(false);

    const handleOpenMoneyControl = (itemId: number) => {
        salePointStore.staticDataPos.id = itemId.toString();
        setShowDropdown(true);
        salePointStore.showModal();
    };


    const filteredPosList = salePointStore.listPos.filter((item: any) =>
        salePointStore.staticDataPos.location_id === item.location_id
    );

    const formatDate = (date: string | null): string => {
        if (!date) return "-";
        return `${new Date(date).toLocaleDateString('vi-VN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        })} ${new Date(date).toLocaleTimeString('vi-VN', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        })}`;
    };


    useEffect(() => {
        salePointStore.getLocation();
        salePointStore.getMachinePosByUser();
    }, []);

    return (
        <div className="SalePointContainer">
            <div className="mainContentSalePoint">
                <div className="header">
                    <div className="header__title">POS</div>
                    <div className="dropdown">
                        <img src="/assets/icon/store.svg" alt="Store" />
                        <div className="dropdown__location">
                            <SelectCustom
                                list={salePointStore.listLocation.filter((item: any) => item.status === 1)}
                                placeholder={'Chọn địa điểm'}
                                onChange={(value: any) => {
                                    salePointStore.staticDataPos.location_id = value;
                                    salePointStore.staticDataPos.store_id = value;
                                    salePointStore.getMachinePosByUser(true);
                                }}
                                selected={() =>
                                    salePointStore.listLocation.find(
                                        (item: any) => item.id === salePointStore.staticDataPos.location_id
                                    )?.name
                                }
                                getLabel={(item: any) => {
                                    return item.name;
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="list-item">
                    {salePointStore.isLoading ? (
                        <div className="loading_data">
                            <Loading />
                        </div>
                    ) : filteredPosList.length > 0 ? (
                        filteredPosList
                            .slice()
                            .sort((a: any, b: any) => a.id - b.id)
                            .map((item: any) => {
                                const content = (
                                    <div className="selectPopoverPos">
                                        <p>
                                            <Link to={`/pos/chi-tiet-don-hang/${item.id}`} style={{ textDecoration: "none", color: "#061020" }}>
                                                Đơn hàng
                                            </Link>
                                        </p>
                                        <p>
                                            <Link to={`/pos/phien-may/${item.id}`} style={{ textDecoration: "none", color: "#061020" }}>
                                                Phiên
                                            </Link>
                                        </p>
                                    </div>
                                );

                                return (
                                    <div key={item.id} className="card list-pos-widget">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <h5 className="card-title"
                                                    style={{
                                                        width: "320px",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                    }}

                                                >{item.name}</h5>
                                                <Popover content={content} trigger="click">
                                                    <img
                                                        src="/assets/icon/icon-option.svg"
                                                        alt="Option"
                                                        className="option"
                                                        style={{cursor: "pointer"}}
                                                    />
                                                </Popover>
                                            </div>
                                            <ul className="list-unstyled">
                                                <li>{item.is_working === null || item.is_working === 0 ? `Đóng: ${formatDate(item.end_working)}` : `Mở: ${formatDate(item.start_working)}`}</li>
                                                <li>
                                                    {item.is_working === null || item.is_working === 0
                                                        ? `Số dư: ${item.meta_end_amount !== null && item.meta_end_amount !== undefined ?
                                                            (item.meta_end_amount === 0 ? '0 đ' : formatCurrency(item.meta_end_amount)) : '-'}`
                                                        : `Số dư: ${item.meta_start_amount !== null && item.meta_start_amount !== undefined ?
                                                            (item.meta_start_amount === 0 ? '0 đ' : formatCurrency(item.meta_start_amount)) : '-'}`
                                                    }
                                                </li>
                                                <li className="user-info">
                                                    Phụ trách chính:
                                                    {item.user
                                                        ? (
                                                            <div className="user-info">
                                                                {item.user.avatar
                                                                    ? <img src={item.user.avatar} alt={item.user.name}
                                                                           className="user-avatar"/>
                                                                    : <img src="/assets/icon/icon_user.svg" alt="User"
                                                                           className="user-avatar"/>
                                                                }
                                                                <span className="user-name">{item.user.name}</span>
                                                            </div>
                                                        )
                                                        : '-'}
                                                </li>
                                            </ul>
                                            {item.is_working === 0 ? (
                                                <button
                                                    className="btn w-100 mt-3 btn-primary"
                                                    onClick={() => handleOpenMoneyControl(item.id)}
                                                >
                                                    Mở máy tính tiền
                                                </button>
                                            ) : (
                                                <Link
                                                    to={`/pos/${item.id}/san-pham`}
                                                    className="btn w-100 mt-3 btn-warning"
                                                    onClick={() => {
                                                        salePointStore.staticDataPos.id = item.id.toString();
                                                    }}
                                                >
                                                    Tiếp tục bán
                                                </Link>

                                            )}
                                        </div>
                                    </div>
                                );
                            })
                    ) : (
                        <div className="no_content">
                            <NoPos />
                        </div>
                    )}
                </div>
            </div>
            <MoneyControl/>
        </div>
    );
}

export default observer(SalePointPage);
